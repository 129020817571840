import { createAsyncThunk } from "@reduxjs/toolkit";
import { ClassObject, CreateCurriculumMutation, CreateCurriculumMutationVariables, Curriculum, GetCurriculumQuery, GetCurriculumQueryVariables, ListCurriculumsQuery, ListCurriculumsQueryVariables, UpdateCurriculumDataMutation, UpdateCurriculumDataMutationVariables, UpdatedClassAndStudentRecords } from "src/globalUtils/API";
import { AppDispatch, RootState } from "../store"
import * as mutations from 'src/globalUtils/graphql/mutations';
import * as queries from 'src/globalUtils/graphql/queries';
import { enqueueErrorSnackbarMessage } from "../notifications/snackbar-notification-util"
import { GraphQLQuery, GraphQLResult } from "aws-amplify/api";
import { graphQLClient } from "../util";
import { updateFetchStatus } from "./schoolManagementSlice";

type GetCurriculumByCurriculumIDProps = {
    variables: GetCurriculumQueryVariables
}

export const getCurriculumByCurriculumID = async ({ variables }: GetCurriculumByCurriculumIDProps) => {
    const response: GraphQLResult<GetCurriculumQuery> = await graphQLClient.graphql<GraphQLQuery<GetCurriculumQuery>>({
        query: queries.getCurriculum,
        variables
    });

    if (response.errors) {
        throw new Error(JSON.stringify(response.errors))
    }

    return response.data.getCurriculum!!
}

export const createCurriculum = createAsyncThunk<
    {
        organizationID: string,
        curriculum: Curriculum,
    },
    {
        mutationVariables: CreateCurriculumMutationVariables,
    },
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('schoolManagement/createCurriculum', async ({ mutationVariables }, { dispatch, getState }) => {
    const response: GraphQLResult<CreateCurriculumMutation> = await graphQLClient.graphql<GraphQLQuery<CreateCurriculumMutation>>({
        query: mutations.createCurriculum,
        variables: mutationVariables
    });

    if (response.errors) {
        throw new Error(JSON.stringify(response.errors))
    }

    const curriculum = response.data.createCurriculum
    return {
        organizationID: mutationVariables.organizationID,
        curriculum
    }
})

export const updateCurriculum = createAsyncThunk<
    {
        organizationID: string,
        updatedCurriculum: Curriculum,
        updatedClasses: UpdatedClassAndStudentRecords[]
    } | undefined,
    {
        organizationID: string,
        mutationVariables: UpdateCurriculumDataMutationVariables,
    },
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('schoolManagement/updateCurriculum', async ({ organizationID, mutationVariables }, { dispatch, getState }) => {
    const response: GraphQLResult<UpdateCurriculumDataMutation> = await graphQLClient.graphql<GraphQLQuery<UpdateCurriculumDataMutation>>({
        query: mutations.updateCurriculumData,
        variables: mutationVariables
    });

    if (response.errors) {
        throw new Error(JSON.stringify(response.errors))
    }

    const result = response.data.updateCurriculumData
    if (result.actionableErrorMsg) {
        enqueueErrorSnackbarMessage(result.actionableErrorMsg)
        return undefined
    }

    return {
        organizationID,
        updatedCurriculum: result.updatedCurriculum!!,
        updatedClasses: result.updatedClasses ?? []
    }
})

export const listCurriculumsKey = (orgId: string) => `listCurriculumsKey-${orgId}`
export const listCurriculums = createAsyncThunk<
    {
        organizationID: string,
        curriculums: Curriculum[],
    } | undefined,
    {
        queryVariables: ListCurriculumsQueryVariables,
    },
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('schoolManagement/listCurriculums', async ({ queryVariables }, { dispatch, getState }) => {
    const orgId = queryVariables.organizationId
    if (['fulfilled', 'pending'].includes(getState().schoolManagement.fetchStatusByKey[listCurriculumsKey(orgId)])) {
        return undefined; // skip fetch, if it's pending or already fulfilled
    }

    dispatch(updateFetchStatus({
        fetchKey: listCurriculumsKey(orgId),
        orgId,
        status: 'pending'
    }))

    let shouldFetch = true
    let { nextToken } = queryVariables
    let results: Curriculum[] = []

    /* eslint-disable no-await-in-loop */
    while (shouldFetch) {
        const variables: ListCurriculumsQueryVariables = {
            organizationId: queryVariables.organizationId,
            nextToken
        }
        const response: GraphQLResult<ListCurriculumsQuery> = await graphQLClient.graphql<GraphQLQuery<ListCurriculumsQuery>>({
            query: queries.listCurriculums,
            variables
        });
        nextToken = response.data?.listCurriculums?.nextToken
        shouldFetch = nextToken !== null && nextToken !== undefined
        results = results.concat(response.data!!.listCurriculums!!.items!! as Curriculum[])
    }

    return {
        organizationID: queryVariables.organizationId,
        curriculums: results
    }
})
