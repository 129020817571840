/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getGlobalConfigurations = /* GraphQL */ `query GetGlobalConfigurations($id: ID!) {
  getGlobalConfigurations(id: $id) {
    id
    latestAppVersion
    miniApps {
      id
      type
      timestamp
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetGlobalConfigurationsQueryVariables,
  APITypes.GetGlobalConfigurationsQuery
>;
export const listGlobalConfigurations = /* GraphQL */ `query ListGlobalConfigurations(
  $id: ID
  $filter: ModelGlobalConfigurationsFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listGlobalConfigurations(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      latestAppVersion
      miniApps {
        id
        type
        timestamp
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListGlobalConfigurationsQueryVariables,
  APITypes.ListGlobalConfigurationsQuery
>;
export const getRobotAction = /* GraphQL */ `query GetRobotAction($organizationID: ID!, $actionID: ID!) {
  getRobotAction(organizationID: $organizationID, actionID: $actionID) {
    organizationID
    actionID
    actionName
    actionNameEmbedding
    embeddingModel
    textToSayEn
    textToSayZh
    actionBlocklyProjectId
    actionBlocklyProjectVersion
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetRobotActionQueryVariables,
  APITypes.GetRobotActionQuery
>;
export const listRobotActions = /* GraphQL */ `query ListRobotActions(
  $organizationID: ID
  $actionID: ModelIDKeyConditionInput
  $filter: ModelRobotActionFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listRobotActions(
    organizationID: $organizationID
    actionID: $actionID
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      organizationID
      actionID
      actionName
      actionNameEmbedding
      embeddingModel
      textToSayEn
      textToSayZh
      actionBlocklyProjectId
      actionBlocklyProjectVersion
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRobotActionsQueryVariables,
  APITypes.ListRobotActionsQuery
>;
export const getRobotCustomAction = /* GraphQL */ `query GetRobotCustomAction($id: ID!) {
  getRobotCustomAction(id: $id) {
    id
    knowledgeBaseID
    actionName
    actionDescription
    actionEmbedding
    embeddingModel
    actionType
    textAnswer {
      textToSayEn
      textToSayZh
      __typename
    }
    codelab {
      projectID
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetRobotCustomActionQueryVariables,
  APITypes.GetRobotCustomActionQuery
>;
export const listRobotCustomActions = /* GraphQL */ `query ListRobotCustomActions(
  $id: ID
  $filter: ModelRobotCustomActionFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listRobotCustomActions(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      knowledgeBaseID
      actionName
      actionDescription
      actionEmbedding
      embeddingModel
      actionType
      textAnswer {
        textToSayEn
        textToSayZh
        __typename
      }
      codelab {
        projectID
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRobotCustomActionsQueryVariables,
  APITypes.ListRobotCustomActionsQuery
>;
export const getAudioRecording = /* GraphQL */ `query GetAudioRecording($s3bucketKey: ID!) {
  getAudioRecording(s3bucketKey: $s3bucketKey) {
    s3bucketKey
    whisperResult
    googleSpeechToText
    awsTranscribeResult
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAudioRecordingQueryVariables,
  APITypes.GetAudioRecordingQuery
>;
export const listAudioRecordings = /* GraphQL */ `query ListAudioRecordings(
  $s3bucketKey: ID
  $filter: ModelAudioRecordingFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listAudioRecordings(
    s3bucketKey: $s3bucketKey
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      s3bucketKey
      whisperResult
      googleSpeechToText
      awsTranscribeResult
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAudioRecordingsQueryVariables,
  APITypes.ListAudioRecordingsQuery
>;
export const getConversationHistory = /* GraphQL */ `query GetConversationHistory($deviceID: ID!, $startDateTime: String!) {
  getConversationHistory(deviceID: $deviceID, startDateTime: $startDateTime) {
    deviceID
    startDateTime
    messages {
      role
      content
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetConversationHistoryQueryVariables,
  APITypes.GetConversationHistoryQuery
>;
export const listConversationHistories = /* GraphQL */ `query ListConversationHistories(
  $deviceID: ID
  $startDateTime: ModelStringKeyConditionInput
  $filter: ModelConversationHistoryFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listConversationHistories(
    deviceID: $deviceID
    startDateTime: $startDateTime
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      deviceID
      startDateTime
      messages {
        role
        content
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListConversationHistoriesQueryVariables,
  APITypes.ListConversationHistoriesQuery
>;
export const getQuestionAndAnswerRecordsV2 = /* GraphQL */ `query GetQuestionAndAnswerRecordsV2($deviceID: ID!, $dateTime: String!) {
  getQuestionAndAnswerRecordsV2(deviceID: $deviceID, dateTime: $dateTime) {
    deviceID
    dateTime
    source
    question
    answer
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetQuestionAndAnswerRecordsV2QueryVariables,
  APITypes.GetQuestionAndAnswerRecordsV2Query
>;
export const listQuestionAndAnswerRecordsV2s = /* GraphQL */ `query ListQuestionAndAnswerRecordsV2s(
  $deviceID: ID
  $dateTime: ModelStringKeyConditionInput
  $filter: ModelQuestionAndAnswerRecordsV2FilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listQuestionAndAnswerRecordsV2s(
    deviceID: $deviceID
    dateTime: $dateTime
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      deviceID
      dateTime
      source
      question
      answer
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListQuestionAndAnswerRecordsV2sQueryVariables,
  APITypes.ListQuestionAndAnswerRecordsV2sQuery
>;
export const getAppLogging = /* GraphQL */ `query GetAppLogging($userID: ID!, $dateTime: String!) {
  getAppLogging(userID: $userID, dateTime: $dateTime) {
    userID
    dateTime
    namespace
    message
    stacktrace
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAppLoggingQueryVariables,
  APITypes.GetAppLoggingQuery
>;
export const listAppLoggings = /* GraphQL */ `query ListAppLoggings(
  $userID: ID
  $dateTime: ModelStringKeyConditionInput
  $filter: ModelAppLoggingFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listAppLoggings(
    userID: $userID
    dateTime: $dateTime
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      userID
      dateTime
      namespace
      message
      stacktrace
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAppLoggingsQueryVariables,
  APITypes.ListAppLoggingsQuery
>;
export const getOrganizationData = /* GraphQL */ `query GetOrganizationData($organizationID: ID!) {
  getOrganizationData(organizationID: $organizationID) {
    organizationID
    name
    compreFaceFaceRecognitionApiKey
    usePaidSpeechToText
    usePaidTextToSpeech
    miniApps {
      id
      type
      timestamp
      __typename
    }
    createdAt
    updatedAt
    isSchoolManagementEnabled
    isKebbiForBusinessEnabled
    allowedOrgRoles
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrganizationDataQueryVariables,
  APITypes.GetOrganizationDataQuery
>;
export const listOrganizationData = /* GraphQL */ `query ListOrganizationData(
  $organizationID: ID
  $filter: ModelOrganizationDataFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listOrganizationData(
    organizationID: $organizationID
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      organizationID
      name
      compreFaceFaceRecognitionApiKey
      usePaidSpeechToText
      usePaidTextToSpeech
      miniApps {
        id
        type
        timestamp
        __typename
      }
      createdAt
      updatedAt
      isSchoolManagementEnabled
      isKebbiForBusinessEnabled
      allowedOrgRoles
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrganizationDataQueryVariables,
  APITypes.ListOrganizationDataQuery
>;
export const getFaceRecognitionMomentsForImprovementsLog = /* GraphQL */ `query GetFaceRecognitionMomentsForImprovementsLog($id: ID!) {
  getFaceRecognitionMomentsForImprovementsLog(id: $id) {
    id
    kebbiDeviceId
    organizationId
    recognitionHistory {
      results {
        similarity
        subject
        __typename
      }
      __typename
    }
    outcome
    logReason
    recognizedUser
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFaceRecognitionMomentsForImprovementsLogQueryVariables,
  APITypes.GetFaceRecognitionMomentsForImprovementsLogQuery
>;
export const listFaceRecognitionMomentsForImprovementsLogs = /* GraphQL */ `query ListFaceRecognitionMomentsForImprovementsLogs(
  $id: ID
  $filter: ModelFaceRecognitionMomentsForImprovementsLogFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listFaceRecognitionMomentsForImprovementsLogs(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      kebbiDeviceId
      organizationId
      recognitionHistory {
        results {
          similarity
          subject
          __typename
        }
        __typename
      }
      outcome
      logReason
      recognizedUser
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFaceRecognitionMomentsForImprovementsLogsQueryVariables,
  APITypes.ListFaceRecognitionMomentsForImprovementsLogsQuery
>;
export const getKebbiDevice = /* GraphQL */ `query GetKebbiDevice($id: ID!) {
  getKebbiDevice(id: $id) {
    id
    organizationID
    cognitoIdentityID
    cognitoUserID
    password
    deviceServiceStatus {
      serviceName
      expirationDate
      __typename
    }
    registeredFaces {
      file {
        lastUpdatedTimestamp
        name
        category
        identityId
        __typename
      }
      fullName
      preferredName
      __typename
    }
    version
    createdAt
    updatedAt
    isTestDevice
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetKebbiDeviceQueryVariables,
  APITypes.GetKebbiDeviceQuery
>;
export const listKebbiDevices = /* GraphQL */ `query ListKebbiDevices(
  $id: ID
  $filter: ModelKebbiDeviceFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listKebbiDevices(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      organizationID
      cognitoIdentityID
      cognitoUserID
      password
      deviceServiceStatus {
        serviceName
        expirationDate
        __typename
      }
      registeredFaces {
        file {
          lastUpdatedTimestamp
          name
          category
          identityId
          __typename
        }
        fullName
        preferredName
        __typename
      }
      version
      createdAt
      updatedAt
      isTestDevice
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListKebbiDevicesQueryVariables,
  APITypes.ListKebbiDevicesQuery
>;
export const getOrganizationUser = /* GraphQL */ `query GetOrganizationUser($organizationID: ID!, $username: String!) {
  getOrganizationUser(organizationID: $organizationID, username: $username) {
    organizationID
    username
    status
    awards {
      numAwards
      __typename
    }
    role
    createdAt
    updatedAt
    isShadowAdmin
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrganizationUserQueryVariables,
  APITypes.GetOrganizationUserQuery
>;
export const listOrganizationUsers = /* GraphQL */ `query ListOrganizationUsers(
  $organizationID: ID
  $username: ModelStringKeyConditionInput
  $filter: ModelOrganizationUserFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listOrganizationUsers(
    organizationID: $organizationID
    username: $username
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      organizationID
      username
      status
      awards {
        numAwards
        __typename
      }
      role
      createdAt
      updatedAt
      isShadowAdmin
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrganizationUsersQueryVariables,
  APITypes.ListOrganizationUsersQuery
>;
export const getUser = /* GraphQL */ `query GetUser($userName: ID!) {
  getUser(userName: $userName) {
    userName
    cognitoIdentityID
    password
    organizations
    profileImage {
      s3Key
      versionNumber
      documentType
      __typename
    }
    parentName
    legalFullName
    preferredName
    country
    email
    phoneNumber
    dateOfBirth
    about
    notes
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $userName: ID
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listUsers(
    userName: $userName
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      userName
      cognitoIdentityID
      password
      organizations
      profileImage {
        s3Key
        versionNumber
        documentType
        __typename
      }
      parentName
      legalFullName
      preferredName
      country
      email
      phoneNumber
      dateOfBirth
      about
      notes
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const getTempAuthToken = /* GraphQL */ `query GetTempAuthToken($id: ID!) {
  getTempAuthToken(id: $id) {
    id
    username
    expirationAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTempAuthTokenQueryVariables,
  APITypes.GetTempAuthTokenQuery
>;
export const listTempAuthTokens = /* GraphQL */ `query ListTempAuthTokens(
  $id: ID
  $filter: ModelTempAuthTokenFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listTempAuthTokens(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      username
      expirationAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTempAuthTokensQueryVariables,
  APITypes.ListTempAuthTokensQuery
>;
export const getThirdParthAuthToken = /* GraphQL */ `query GetThirdParthAuthToken($thirdPartyName: ID!) {
  getThirdParthAuthToken(thirdPartyName: $thirdPartyName) {
    thirdPartyName
    token
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetThirdParthAuthTokenQueryVariables,
  APITypes.GetThirdParthAuthTokenQuery
>;
export const listThirdParthAuthTokens = /* GraphQL */ `query ListThirdParthAuthTokens(
  $thirdPartyName: ID
  $filter: ModelThirdParthAuthTokenFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listThirdParthAuthTokens(
    thirdPartyName: $thirdPartyName
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      thirdPartyName
      token
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListThirdParthAuthTokensQueryVariables,
  APITypes.ListThirdParthAuthTokensQuery
>;
export const getActivationCode = /* GraphQL */ `query GetActivationCode($id: ID!) {
  getActivationCode(id: $id) {
    id
    durationInDays
    serviceNames
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetActivationCodeQueryVariables,
  APITypes.GetActivationCodeQuery
>;
export const listActivationCodes = /* GraphQL */ `query ListActivationCodes(
  $id: ID
  $filter: ModelActivationCodeFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listActivationCodes(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      durationInDays
      serviceNames
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListActivationCodesQueryVariables,
  APITypes.ListActivationCodesQuery
>;
export const getKebbiMaterials = /* GraphQL */ `query GetKebbiMaterials($materialType: KebbiMaterialType!, $page: Int!) {
  getKebbiMaterials(materialType: $materialType, page: $page) {
    materialType
    page
    materials {
      title
      i18n {
        en_US
        __typename
      }
      identityID
      fileName
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetKebbiMaterialsQueryVariables,
  APITypes.GetKebbiMaterialsQuery
>;
export const listKebbiMaterials = /* GraphQL */ `query ListKebbiMaterials(
  $materialType: KebbiMaterialType
  $page: ModelIntKeyConditionInput
  $filter: ModelKebbiMaterialsFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listKebbiMaterials(
    materialType: $materialType
    page: $page
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      materialType
      page
      materials {
        title
        i18n {
          en_US
          __typename
        }
        identityID
        fileName
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListKebbiMaterialsQueryVariables,
  APITypes.ListKebbiMaterialsQuery
>;
export const getBlocklyProject = /* GraphQL */ `query GetBlocklyProject($id: ID!) {
  getBlocklyProject(id: $id) {
    id
    workspaceJson
    projectName
    identityID
    files {
      lastUpdatedTimestamp
      name
      category
      identityId
      __typename
    }
    filesV2 {
      s3Key
      versionNumber
      documentType
      __typename
    }
    owner
    isMiniAppProject
    createdAt
    updatedAt
    machineLearningProjectIDs
    genAIAssistantIDs
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBlocklyProjectQueryVariables,
  APITypes.GetBlocklyProjectQuery
>;
export const listBlocklyProjects = /* GraphQL */ `query ListBlocklyProjects(
  $id: ID
  $filter: ModelBlocklyProjectFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listBlocklyProjects(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      workspaceJson
      projectName
      identityID
      files {
        lastUpdatedTimestamp
        name
        category
        identityId
        __typename
      }
      filesV2 {
        s3Key
        versionNumber
        documentType
        __typename
      }
      owner
      isMiniAppProject
      createdAt
      updatedAt
      machineLearningProjectIDs
      genAIAssistantIDs
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBlocklyProjectsQueryVariables,
  APITypes.ListBlocklyProjectsQuery
>;
export const getScratchAccount = /* GraphQL */ `query GetScratchAccount($lyzaUsername: ID!) {
  getScratchAccount(lyzaUsername: $lyzaUsername) {
    lyzaUsername
    orgID
    status
    createAccountFailedDateTimes
    confirmEmailFailedDateTimes
    scratchUsername
    scratchEmail
    scratchPassword
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetScratchAccountQueryVariables,
  APITypes.GetScratchAccountQuery
>;
export const listScratchAccounts = /* GraphQL */ `query ListScratchAccounts(
  $lyzaUsername: ID
  $filter: ModelScratchAccountFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listScratchAccounts(
    lyzaUsername: $lyzaUsername
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      lyzaUsername
      orgID
      status
      createAccountFailedDateTimes
      confirmEmailFailedDateTimes
      scratchUsername
      scratchEmail
      scratchPassword
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListScratchAccountsQueryVariables,
  APITypes.ListScratchAccountsQuery
>;
export const getUserSubscriptionEventStatus = /* GraphQL */ `query GetUserSubscriptionEventStatus($userID: ID!) {
  getUserSubscriptionEventStatus(userID: $userID) {
    userID
    machineLearning {
      tfjsToTfliteConversionStatus
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserSubscriptionEventStatusQueryVariables,
  APITypes.GetUserSubscriptionEventStatusQuery
>;
export const listUserSubscriptionEventStatuses = /* GraphQL */ `query ListUserSubscriptionEventStatuses(
  $userID: ID
  $filter: ModelUserSubscriptionEventStatusFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listUserSubscriptionEventStatuses(
    userID: $userID
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      userID
      machineLearning {
        tfjsToTfliteConversionStatus
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserSubscriptionEventStatusesQueryVariables,
  APITypes.ListUserSubscriptionEventStatusesQuery
>;
export const getUserWhatsAppMessage = /* GraphQL */ `query GetUserWhatsAppMessage($messageID: ID!) {
  getUserWhatsAppMessage(messageID: $messageID) {
    messageID
    customerNumber
    businessPhoneNumber
    isFromBizToCustomer
    timestampInSecond
    status
    textMessage
    imageS3Key {
      s3Key
      versionNumber
      documentType
      __typename
    }
    videoS3Key {
      s3Key
      versionNumber
      documentType
      __typename
    }
    documentS3Key {
      s3Key
      versionNumber
      documentType
      __typename
    }
    reactionEmoji
    quotedMessageId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserWhatsAppMessageQueryVariables,
  APITypes.GetUserWhatsAppMessageQuery
>;
export const listUserWhatsAppMessages = /* GraphQL */ `query ListUserWhatsAppMessages(
  $messageID: ID
  $filter: ModelUserWhatsAppMessageFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listUserWhatsAppMessages(
    messageID: $messageID
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      messageID
      customerNumber
      businessPhoneNumber
      isFromBizToCustomer
      timestampInSecond
      status
      textMessage
      imageS3Key {
        s3Key
        versionNumber
        documentType
        __typename
      }
      videoS3Key {
        s3Key
        versionNumber
        documentType
        __typename
      }
      documentS3Key {
        s3Key
        versionNumber
        documentType
        __typename
      }
      reactionEmoji
      quotedMessageId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserWhatsAppMessagesQueryVariables,
  APITypes.ListUserWhatsAppMessagesQuery
>;
export const getOnlineMeetingJoinLink = /* GraphQL */ `query GetOnlineMeetingJoinLink(
  $targetUsername: ID!
  $targetUserDisplayName: String!
  $targetUserRole: OrgRole!
  $meetingID: ID!
  $classID: ID!
  $sessionIndex: Int!
) {
  getOnlineMeetingJoinLink(
    targetUsername: $targetUsername
    targetUserDisplayName: $targetUserDisplayName
    targetUserRole: $targetUserRole
    meetingID: $meetingID
    classID: $classID
    sessionIndex: $sessionIndex
  )
}
` as GeneratedQuery<
  APITypes.GetOnlineMeetingJoinLinkQueryVariables,
  APITypes.GetOnlineMeetingJoinLinkQuery
>;
export const listTextEmbeddingsByResourceIDsMax60 = /* GraphQL */ `query ListTextEmbeddingsByResourceIDsMax60(
  $inputs: [ListTextEmbeddingsByResourceIDsInput!]!
) {
  listTextEmbeddingsByResourceIDsMax60(inputs: $inputs) {
    resourceID
    shouldContinueFetch
    embeddings {
      resourceID
      chunkIndex
      vector
      content
      embeddingModel
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTextEmbeddingsByResourceIDsMax60QueryVariables,
  APITypes.ListTextEmbeddingsByResourceIDsMax60Query
>;
export const listQuizCatalogsByOwnerID = /* GraphQL */ `query ListQuizCatalogsByOwnerID(
  $ownerID: ID!
  $nextToken: String
  $limit: Int
) {
  listQuizCatalogsByOwnerID(
    ownerID: $ownerID
    nextToken: $nextToken
    limit: $limit
  ) {
    items {
      id
      ownerID
      ownerType
      name
      description
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListQuizCatalogsByOwnerIDQueryVariables,
  APITypes.ListQuizCatalogsByOwnerIDQuery
>;
export const getQuizCatalogByID = /* GraphQL */ `query GetQuizCatalogByID($quizCatalogID: ID!) {
  getQuizCatalogByID(quizCatalogID: $quizCatalogID) {
    id
    ownerID
    ownerType
    name
    description
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetQuizCatalogByIDQueryVariables,
  APITypes.GetQuizCatalogByIDQuery
>;
export const listQuizesByCatalogID = /* GraphQL */ `query ListQuizesByCatalogID($catalogID: ID!, $nextToken: String, $limit: Int) {
  listQuizesByCatalogID(
    catalogID: $catalogID
    nextToken: $nextToken
    limit: $limit
  ) {
    items {
      id
      quizCatalogID
      sequence
      question
      type
      mcqAnswers {
        text
        isCorrectAnswer
        __typename
      }
      trueFalseAnswer
      textAnswer
      answerExplanation
      score
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListQuizesByCatalogIDQueryVariables,
  APITypes.ListQuizesByCatalogIDQuery
>;
export const getOpenAIRealTimeWebRTCSession = /* GraphQL */ `query GetOpenAIRealTimeWebRTCSession($username: ID!) {
  getOpenAIRealTimeWebRTCSession(username: $username)
}
` as GeneratedQuery<
  APITypes.GetOpenAIRealTimeWebRTCSessionQueryVariables,
  APITypes.GetOpenAIRealTimeWebRTCSessionQuery
>;
export const getBlocklyProjectByID = /* GraphQL */ `query GetBlocklyProjectByID($id: ID!) {
  getBlocklyProjectByID(id: $id) {
    id
    workspaceJson
    projectName
    identityID
    files {
      lastUpdatedTimestamp
      name
      category
      identityId
      __typename
    }
    filesV2 {
      s3Key
      versionNumber
      documentType
      __typename
    }
    owner
    isMiniAppProject
    createdAt
    updatedAt
    machineLearningProjectIDs
    genAIAssistantIDs
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBlocklyProjectByIDQueryVariables,
  APITypes.GetBlocklyProjectByIDQuery
>;
export const getBlocklyProjectsByOwner = /* GraphQL */ `query GetBlocklyProjectsByOwner(
  $owner: String!
  $nextToken: String
  $limit: Int
) {
  getBlocklyProjectsByOwner(
    owner: $owner
    nextToken: $nextToken
    limit: $limit
  ) {
    items {
      id
      workspaceJson
      projectName
      identityID
      files {
        lastUpdatedTimestamp
        name
        category
        identityId
        __typename
      }
      filesV2 {
        s3Key
        versionNumber
        documentType
        __typename
      }
      owner
      isMiniAppProject
      createdAt
      updatedAt
      machineLearningProjectIDs
      genAIAssistantIDs
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBlocklyProjectsByOwnerQueryVariables,
  APITypes.GetBlocklyProjectsByOwnerQuery
>;
export const listLyzaAppMiniAppsByOrgID = /* GraphQL */ `query ListLyzaAppMiniAppsByOrgID(
  $orgID: String!
  $nextToken: String
  $limit: Int
) {
  listLyzaAppMiniAppsByOrgID(
    orgID: $orgID
    nextToken: $nextToken
    limit: $limit
  ) {
    items {
      orgID
      codelabProjectID
      lyzaAppUsernames
      name
      profileImageS3File {
        s3Key
        versionNumber
        documentType
        __typename
      }
      shouldDisablePowerKey
      shouldLockRobotWheelMovement
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListLyzaAppMiniAppsByOrgIDQueryVariables,
  APITypes.ListLyzaAppMiniAppsByOrgIDQuery
>;
export const listWebsiteKnowledgeBaseUrlsByKnowledgeBaseId = /* GraphQL */ `query ListWebsiteKnowledgeBaseUrlsByKnowledgeBaseId(
  $knowledgeBaseId: String!
  $nextToken: String
  $limit: Int
) {
  listWebsiteKnowledgeBaseUrlsByKnowledgeBaseId(
    knowledgeBaseId: $knowledgeBaseId
    nextToken: $nextToken
    limit: $limit
  ) {
    items {
      knowledgeBaseID
      url
      isEnabledDecision
      suggestedDisableUrlConfidence
      suggestedDisableUrlReason
      htmlVersionHash
      pageContentS3File {
        s3Key
        versionNumber
        documentType
        __typename
      }
      ragContentS3File {
        s3Key
        versionNumber
        documentType
        __typename
      }
      ragContentOpenAIFileId
      isScraping
      lastScrapeDateTime
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListWebsiteKnowledgeBaseUrlsByKnowledgeBaseIdQueryVariables,
  APITypes.ListWebsiteKnowledgeBaseUrlsByKnowledgeBaseIdQuery
>;
export const listConversationHistoryByDeviceIdAndDateRangeInclusive = /* GraphQL */ `query ListConversationHistoryByDeviceIdAndDateRangeInclusive(
  $deviceID: String!
  $startDate: AWSDate!
  $endDate: AWSDate!
  $nextToken: String
  $limit: Int
) {
  listConversationHistoryByDeviceIdAndDateRangeInclusive(
    deviceID: $deviceID
    startDate: $startDate
    endDate: $endDate
    nextToken: $nextToken
    limit: $limit
  ) {
    items {
      deviceID
      startDateTime
      messages {
        role
        content
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListConversationHistoryByDeviceIdAndDateRangeInclusiveQueryVariables,
  APITypes.ListConversationHistoryByDeviceIdAndDateRangeInclusiveQuery
>;
export const listOrganizationUsersByOrganizationID = /* GraphQL */ `query ListOrganizationUsersByOrganizationID(
  $organizationID: ID!
  $organizationName: String!
  $nextToken: String
  $limit: Int
) {
  listOrganizationUsersByOrganizationID(
    organizationID: $organizationID
    organizationName: $organizationName
    nextToken: $nextToken
    limit: $limit
  ) {
    items {
      user {
        userName
        cognitoIdentityID
        password
        organizations
        profileImage {
          s3Key
          versionNumber
          documentType
          __typename
        }
        parentName
        legalFullName
        preferredName
        country
        email
        phoneNumber
        dateOfBirth
        about
        notes
        createdAt
        updatedAt
        __typename
      }
      organizations {
        userOrg {
          organizationID
          username
          status
          awards {
            numAwards
            __typename
          }
          role
          createdAt
          updatedAt
          isShadowAdmin
          __typename
        }
        orgName
        userOrgPermission {
          canReadUsers
          canCreateUser
          canListCurriculums
          canCreateCurriculum
          canListClasses
          canCreateClass
          canDeleteClass
          canSeeAdvancedAdminPanelForClass
          canChangeClassTime
          canGenerateStudentLoginAuthLink
          canListCentres
          canCreateCentres
          canListClassrooms
          canCreateClassrooms
          canHaveScratchAccount
          canListOrgAwards
          hasOwnAwardsSection
          canCreateOrUpdateAward
          canAssignAward
          canViewAward
          isKebbiForBusinessEnabled
          isCodelabProEnabled
          __typename
        }
        allowedOrgRoles
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrganizationUsersByOrganizationIDQueryVariables,
  APITypes.ListOrganizationUsersByOrganizationIDQuery
>;
export const getUserByUsername = /* GraphQL */ `query GetUserByUsername($username: ID!) {
  getUserByUsername(username: $username) {
    user {
      userName
      cognitoIdentityID
      password
      organizations
      profileImage {
        s3Key
        versionNumber
        documentType
        __typename
      }
      parentName
      legalFullName
      preferredName
      country
      email
      phoneNumber
      dateOfBirth
      about
      notes
      createdAt
      updatedAt
      __typename
    }
    organizations {
      userOrg {
        organizationID
        username
        status
        awards {
          numAwards
          __typename
        }
        role
        createdAt
        updatedAt
        isShadowAdmin
        __typename
      }
      orgName
      userOrgPermission {
        canReadUsers
        canCreateUser
        canListCurriculums
        canCreateCurriculum
        canListClasses
        canCreateClass
        canDeleteClass
        canSeeAdvancedAdminPanelForClass
        canChangeClassTime
        canGenerateStudentLoginAuthLink
        canListCentres
        canCreateCentres
        canListClassrooms
        canCreateClassrooms
        canHaveScratchAccount
        canListOrgAwards
        hasOwnAwardsSection
        canCreateOrUpdateAward
        canAssignAward
        canViewAward
        isKebbiForBusinessEnabled
        isCodelabProEnabled
        __typename
      }
      allowedOrgRoles
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserByUsernameQueryVariables,
  APITypes.GetUserByUsernameQuery
>;
export const listKebbiDevicesForOrganization = /* GraphQL */ `query ListKebbiDevicesForOrganization(
  $organizationID: ID!
  $nextToken: String
  $limit: Int
) {
  listKebbiDevicesForOrganization(
    organizationID: $organizationID
    nextToken: $nextToken
    limit: $limit
  ) {
    items {
      id
      organizationID
      cognitoIdentityID
      cognitoUserID
      version
      createdAt
      updatedAt
      isTestDevice
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListKebbiDevicesForOrganizationQueryVariables,
  APITypes.ListKebbiDevicesForOrganizationQuery
>;
export const getRobotCustomActionByID = /* GraphQL */ `query GetRobotCustomActionByID($id: ID!) {
  getRobotCustomActionByID(id: $id) {
    id
    knowledgeBaseID
    actionName
    actionDescription
    actionEmbedding
    embeddingModel
    actionType
    textAnswer {
      textToSayEn
      textToSayZh
      __typename
    }
    codelab {
      projectID
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetRobotCustomActionByIDQueryVariables,
  APITypes.GetRobotCustomActionByIDQuery
>;
export const listRobotCustomActionsByKnowledgeBaseID = /* GraphQL */ `query ListRobotCustomActionsByKnowledgeBaseID(
  $knowledgeBaseID: ID!
  $nextToken: String
  $limit: Int
) {
  listRobotCustomActionsByKnowledgeBaseID(
    knowledgeBaseID: $knowledgeBaseID
    nextToken: $nextToken
    limit: $limit
  ) {
    items {
      id
      knowledgeBaseID
      actionName
      actionDescription
      actionEmbedding
      embeddingModel
      actionType
      textAnswer {
        textToSayEn
        textToSayZh
        __typename
      }
      codelab {
        projectID
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRobotCustomActionsByKnowledgeBaseIDQueryVariables,
  APITypes.ListRobotCustomActionsByKnowledgeBaseIDQuery
>;
export const getKnowledgeBaseByIDs = /* GraphQL */ `query GetKnowledgeBaseByIDs($ids: [ID!]!) {
  getKnowledgeBaseByIDs(ids: $ids) {
    id
    ownerID
    ownerType
    name
    description
    genAIAssistantIDs
    type
    websiteKnowledgeBaseContext {
      domainName
      maxDepthOverride
      fetchAllUrlsJobVisitedUrls
      fetchAllUrlsJobRemainingUrls
      fetchAllUrlsJobStatus
      fetchAllUrlsJobTriggeredAt
      __typename
    }
    consolidatedFileS3Key {
      s3Key
      versionNumber
      documentType
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetKnowledgeBaseByIDsQueryVariables,
  APITypes.GetKnowledgeBaseByIDsQuery
>;
export const listKnowledgeBasesByOwnerID = /* GraphQL */ `query ListKnowledgeBasesByOwnerID(
  $ownerID: ID!
  $nextToken: String
  $limit: Int
) {
  listKnowledgeBasesByOwnerID(
    ownerID: $ownerID
    nextToken: $nextToken
    limit: $limit
  ) {
    items {
      id
      ownerID
      ownerType
      name
      description
      genAIAssistantIDs
      type
      websiteKnowledgeBaseContext {
        domainName
        maxDepthOverride
        fetchAllUrlsJobVisitedUrls
        fetchAllUrlsJobRemainingUrls
        fetchAllUrlsJobStatus
        fetchAllUrlsJobTriggeredAt
        __typename
      }
      consolidatedFileS3Key {
        s3Key
        versionNumber
        documentType
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListKnowledgeBasesByOwnerIDQueryVariables,
  APITypes.ListKnowledgeBasesByOwnerIDQuery
>;
export const getGenAIAssistantByIDs = /* GraphQL */ `query GetGenAIAssistantByIDs($ids: [ID!]!) {
  getGenAIAssistantByIDs(ids: $ids) {
    id
    ownerID
    ownerType
    name
    description
    assistantProvider
    thirdPartyAssistantID
    thirdPartyVectorStoreID
    systemPrompt
    knowledgeBaseIDs
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetGenAIAssistantByIDsQueryVariables,
  APITypes.GetGenAIAssistantByIDsQuery
>;
export const listGenAIAssistantsByOwnerID = /* GraphQL */ `query ListGenAIAssistantsByOwnerID(
  $ownerID: ID!
  $nextToken: String
  $limit: Int
) {
  listGenAIAssistantsByOwnerID(
    ownerID: $ownerID
    nextToken: $nextToken
    limit: $limit
  ) {
    items {
      id
      ownerID
      ownerType
      name
      description
      assistantProvider
      thirdPartyAssistantID
      thirdPartyVectorStoreID
      systemPrompt
      knowledgeBaseIDs
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListGenAIAssistantsByOwnerIDQueryVariables,
  APITypes.ListGenAIAssistantsByOwnerIDQuery
>;
export const listChatThreadsByThreadSecondaryIDAndOwnerId = /* GraphQL */ `query ListChatThreadsByThreadSecondaryIDAndOwnerId(
  $threadSecondaryId: ID!
  $ownerID: ID!
  $shouldIncludeThreadLastMessage: Boolean!
  $startDate: AWSDate!
  $endDate: AWSDate!
  $nextToken: String
  $limit: Int
) {
  listChatThreadsByThreadSecondaryIDAndOwnerId(
    threadSecondaryId: $threadSecondaryId
    ownerID: $ownerID
    shouldIncludeThreadLastMessage: $shouldIncludeThreadLastMessage
    startDate: $startDate
    endDate: $endDate
    nextToken: $nextToken
    limit: $limit
  ) {
    threads {
      threadID
      ownerID
      ownerType
      threadSecondaryIdType
      secondaryID
      openAiThreadID
      createdAt
      updatedAt
      __typename
    }
    genAiThreadsLatestMessages {
      messageID
      threadID
      assistantProvider
      assistantID
      messageText
      robotCustomActionMatchResult {
        matchedId
        failureReason
        actionRelevances {
          id
          relevanceScore
          __typename
        }
        __typename
      }
      robotCustomActionsRelevanceResults {
        actionId
        actionType
        relevance
        __typename
      }
      role
      openAiContext {
        openAiThreadID
        runID
        citations {
          startIndex
          endIndex
          fileId
          quoteText
          __typename
        }
        __typename
      }
      knowledgeBaseContexts {
        resourceID
        resourceType
        robotCustomActionResourceContext {
          question
          answer {
            textToSayEn
            textToSayZh
            __typename
          }
          vectorSimilarityScore
          rerankingScore
          __typename
        }
        websiteKnowledgeBaseResourceContexts {
          chunkIndex
          content
          vectorSimilarityScore
          rerankingScore
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextThreadToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListChatThreadsByThreadSecondaryIDAndOwnerIdQueryVariables,
  APITypes.ListChatThreadsByThreadSecondaryIDAndOwnerIdQuery
>;
export const listChatThreadGenAiMessagesByThreadId = /* GraphQL */ `query ListChatThreadGenAiMessagesByThreadId(
  $threadId: ID!
  $nextToken: String
  $limit: Int
) {
  listChatThreadGenAiMessagesByThreadId(
    threadId: $threadId
    nextToken: $nextToken
    limit: $limit
  ) {
    genAiMessages {
      messageID
      threadID
      assistantProvider
      assistantID
      messageText
      robotCustomActionMatchResult {
        matchedId
        failureReason
        actionRelevances {
          id
          relevanceScore
          __typename
        }
        __typename
      }
      robotCustomActionsRelevanceResults {
        actionId
        actionType
        relevance
        __typename
      }
      role
      openAiContext {
        openAiThreadID
        runID
        citations {
          startIndex
          endIndex
          fileId
          quoteText
          __typename
        }
        __typename
      }
      knowledgeBaseContexts {
        resourceID
        resourceType
        robotCustomActionResourceContext {
          question
          answer {
            textToSayEn
            textToSayZh
            __typename
          }
          vectorSimilarityScore
          rerankingScore
          __typename
        }
        websiteKnowledgeBaseResourceContexts {
          chunkIndex
          content
          vectorSimilarityScore
          rerankingScore
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListChatThreadGenAiMessagesByThreadIdQueryVariables,
  APITypes.ListChatThreadGenAiMessagesByThreadIdQuery
>;
export const getMachineLearningProjectV2 = /* GraphQL */ `query GetMachineLearningProjectV2($id: ID!) {
  getMachineLearningProjectV2(id: $id) {
    id
    ownerID
    ownerType
    projectName
    modelType
    modelS3RootDirectory
    classes {
      id
      name
      samplesZipS3File {
        s3Key
        versionNumber
        documentType
        __typename
      }
      isDisabled
      __typename
    }
    targetLatestModelVersion
    tfjsModelVersion
    tfliteModelVersion
    createdAt
    updatedAt
    blocklyProjectIDs
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMachineLearningProjectV2QueryVariables,
  APITypes.GetMachineLearningProjectV2Query
>;
export const listMachineLearningProjectsByIdAndModelType = /* GraphQL */ `query ListMachineLearningProjectsByIdAndModelType(
  $ownerID: ID!
  $nextToken: String
  $limit: Int
) {
  listMachineLearningProjectsByIdAndModelType(
    ownerID: $ownerID
    nextToken: $nextToken
    limit: $limit
  ) {
    items {
      id
      ownerID
      ownerType
      projectName
      modelType
      modelS3RootDirectory
      classes {
        id
        name
        samplesZipS3File {
          s3Key
          versionNumber
          documentType
          __typename
        }
        isDisabled
        __typename
      }
      targetLatestModelVersion
      tfjsModelVersion
      tfliteModelVersion
      createdAt
      updatedAt
      blocklyProjectIDs
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMachineLearningProjectsByIdAndModelTypeQueryVariables,
  APITypes.ListMachineLearningProjectsByIdAndModelTypeQuery
>;
export const getCodespaceProject = /* GraphQL */ `query GetCodespaceProject($id: ID!) {
  getCodespaceProject(id: $id) {
    id
    ownerID
    ownerType
    projectName
    notebookS3File {
      s3Key
      versionNumber
      documentType
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCodespaceProjectQueryVariables,
  APITypes.GetCodespaceProjectQuery
>;
export const listCodespaceProjectsById = /* GraphQL */ `query ListCodespaceProjectsById(
  $ownerID: ID!
  $nextToken: String
  $limit: Int
) {
  listCodespaceProjectsById(
    ownerID: $ownerID
    nextToken: $nextToken
    limit: $limit
  ) {
    items {
      id
      ownerID
      ownerType
      projectName
      notebookS3File {
        s3Key
        versionNumber
        documentType
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCodespaceProjectsByIdQueryVariables,
  APITypes.ListCodespaceProjectsByIdQuery
>;
export const getS3FileDownloadLinks = /* GraphQL */ `query GetS3FileDownloadLinks($s3Keys: [String!]!) {
  getS3FileDownloadLinks(s3Keys: $s3Keys) {
    s3Key
    s3PreSignedUrl
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetS3FileDownloadLinksQueryVariables,
  APITypes.GetS3FileDownloadLinksQuery
>;
export const getUserProfileImageUploadLinkAndS3Key = /* GraphQL */ `query GetUserProfileImageUploadLinkAndS3Key(
  $username: String!
  $contentType: String!
) {
  getUserProfileImageUploadLinkAndS3Key(
    username: $username
    contentType: $contentType
  ) {
    s3Key
    s3PreSignedUrl
    documentType
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserProfileImageUploadLinkAndS3KeyQueryVariables,
  APITypes.GetUserProfileImageUploadLinkAndS3KeyQuery
>;
export const getFilesUploadLinksAndS3Keys = /* GraphQL */ `query GetFilesUploadLinksAndS3Keys($fileUploadInputs: [S3FileUploadInput!]!) {
  getFilesUploadLinksAndS3Keys(fileUploadInputs: $fileUploadInputs) {
    s3Key
    s3PreSignedUrl
    documentType
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFilesUploadLinksAndS3KeysQueryVariables,
  APITypes.GetFilesUploadLinksAndS3KeysQuery
>;
export const listCentres = /* GraphQL */ `query ListCentres(
  $id: ID
  $organizationID: ID!
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listCentres(
    id: $id
    organizationID: $organizationID
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      organizationID
      data {
        name
        address
        postcode
        country
        __typename
      }
      status
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCentresQueryVariables,
  APITypes.ListCentresQuery
>;
export const listClassrooms = /* GraphQL */ `query ListClassrooms(
  $id: ID
  $organizationID: ID!
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listClassrooms(
    id: $id
    organizationID: $organizationID
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      organizationID
      data {
        centreId
        centre {
          name
          address
          postcode
          country
          __typename
        }
        name
        isOnlineClass
        onlineMeetingPlatform
        onlineMeetingHostAccount
        __typename
      }
      status
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListClassroomsQueryVariables,
  APITypes.ListClassroomsQuery
>;
export const getCurriculum = /* GraphQL */ `query GetCurriculum($id: ID!) {
  getCurriculum(id: $id) {
    id
    organizationID
    data {
      name
      description
      covers {
        s3Key
        versionNumber
        documentType
        __typename
      }
      type
      sessionTemplates {
        sessionName
        sessionDescription
        sessionDurationInMinutes
        evaluationItems {
          metric
          formats {
            format
            required
            __typename
          }
          __typename
        }
        requireSessionPhotoOrVideoFromTeacher
        requireSessionSummaryFromTeacher
        requireEachStudentPhotoOrVideoFromTeacher
        requiredCommunications
        __typename
      }
      endOfClassEvaluationsTemplate {
        metric
        formats {
          format
          required
          __typename
        }
        __typename
      }
      requiredClassLevelCommunications
      __typename
    }
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCurriculumQueryVariables,
  APITypes.GetCurriculumQuery
>;
export const listCurriculums = /* GraphQL */ `query ListCurriculums($organizationId: ID!, $nextToken: String) {
  listCurriculums(organizationId: $organizationId, nextToken: $nextToken) {
    items {
      id
      organizationID
      data {
        name
        description
        covers {
          s3Key
          versionNumber
          documentType
          __typename
        }
        type
        sessionTemplates {
          sessionName
          sessionDescription
          sessionDurationInMinutes
          evaluationItems {
            metric
            formats {
              format
              required
              __typename
            }
            __typename
          }
          requireSessionPhotoOrVideoFromTeacher
          requireSessionSummaryFromTeacher
          requireEachStudentPhotoOrVideoFromTeacher
          requiredCommunications
          __typename
        }
        endOfClassEvaluationsTemplate {
          metric
          formats {
            format
            required
            __typename
          }
          __typename
        }
        requiredClassLevelCommunications
        __typename
      }
      status
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCurriculumsQueryVariables,
  APITypes.ListCurriculumsQuery
>;
export const listClassObjectsWithAttendeeDetailsByUsername = /* GraphQL */ `query ListClassObjectsWithAttendeeDetailsByUsername(
  $username: ID!
  $role: OrgRole!
  $orgId: String!
  $nextToken: String
) {
  listClassObjectsWithAttendeeDetailsByUsername(
    username: $username
    role: $role
    orgId: $orgId
    nextToken: $nextToken
  ) {
    items {
      classObject {
        id
        organizationID
        curriculumId
        curriculum {
          name
          description
          covers {
            s3Key
            versionNumber
            documentType
            __typename
          }
          type
          sessionTemplates {
            sessionName
            sessionDescription
            sessionDurationInMinutes
            evaluationItems {
              metric
              formats {
                format
                required
                __typename
              }
              __typename
            }
            requireSessionPhotoOrVideoFromTeacher
            requireSessionSummaryFromTeacher
            requireEachStudentPhotoOrVideoFromTeacher
            requiredCommunications
            __typename
          }
          endOfClassEvaluationsTemplate {
            metric
            formats {
              format
              required
              __typename
            }
            __typename
          }
          requiredClassLevelCommunications
          __typename
        }
        sessions {
          sessionLevelSharedFiles {
            s3Key
            versionNumber
            documentType
            __typename
          }
          sessionSummary
          startDateTime
          classroom {
            centreId
            centre {
              name
              address
              postcode
              country
              __typename
            }
            name
            isOnlineClass
            onlineMeetingPlatform
            onlineMeetingHostAccount
            __typename
          }
          onlineMeetingID
          __typename
        }
        classLevelSharedFiles {
          s3Key
          versionNumber
          documentType
          __typename
        }
        classCommunicationOption {
          isSendToParent
          sendToNonParentEmail
          sendToNonParentPhoneNumber
          __typename
        }
        status
        createdAt
        updatedAt
        __typename
      }
      students {
        record {
          id
          organizationID
          username
          classID
          sessionRecords {
            isAssigned
            attended
            notAttendedReason
            documents {
              s3Key
              versionNumber
              documentType
              __typename
            }
            sessionEvaluations {
              metric
              results {
                format
                required
                result
                __typename
              }
              __typename
            }
            sentCommunications
            updatedAt
            updatedByUsername
            __typename
          }
          endOfClassEvaluations {
            metric
            results {
              format
              required
              result
              __typename
            }
            __typename
          }
          endOfClassPhotoOrVideos {
            s3Key
            versionNumber
            documentType
            __typename
          }
          sentClassLevelCommunications
          documents {
            s3Key
            versionNumber
            documentType
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        userDetails {
          userName
          cognitoIdentityID
          password
          organizations
          profileImage {
            s3Key
            versionNumber
            documentType
            __typename
          }
          parentName
          legalFullName
          preferredName
          country
          email
          phoneNumber
          dateOfBirth
          about
          notes
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      teachers {
        record {
          id
          organizationID
          username
          classID
          sessionRecords {
            role
            documents {
              s3Key
              versionNumber
              documentType
              __typename
            }
            updatedAt
            updatedByUsername
            __typename
          }
          documents {
            s3Key
            versionNumber
            documentType
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        userDetails {
          userName
          cognitoIdentityID
          password
          organizations
          profileImage {
            s3Key
            versionNumber
            documentType
            __typename
          }
          parentName
          legalFullName
          preferredName
          country
          email
          phoneNumber
          dateOfBirth
          about
          notes
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListClassObjectsWithAttendeeDetailsByUsernameQueryVariables,
  APITypes.ListClassObjectsWithAttendeeDetailsByUsernameQuery
>;
export const getCredentialsFromAuthToken = /* GraphQL */ `query GetCredentialsFromAuthToken($tokenId: ID!) {
  getCredentialsFromAuthToken(tokenId: $tokenId) {
    errorMessage
    username
    pwd
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCredentialsFromAuthTokenQueryVariables,
  APITypes.GetCredentialsFromAuthTokenQuery
>;
export const getScratchAccountCustom = /* GraphQL */ `query GetScratchAccountCustom($lyzaUsername: ID!) {
  getScratchAccountCustom(lyzaUsername: $lyzaUsername) {
    lyzaUsername
    orgID
    status
    createAccountFailedDateTimes
    confirmEmailFailedDateTimes
    scratchUsername
    scratchEmail
    scratchPassword
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetScratchAccountCustomQueryVariables,
  APITypes.GetScratchAccountCustomQuery
>;
export const listScratchProjects = /* GraphQL */ `query ListScratchProjects($scratchUsername: ID!, $scratchPassword: String!) {
  listScratchProjects(
    scratchUsername: $scratchUsername
    scratchPassword: $scratchPassword
  ) {
    id
    title
    isPublished
    datetimeCreatedUtc
    datetimeModifiedUtc
    datetimeShared
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListScratchProjectsQueryVariables,
  APITypes.ListScratchProjectsQuery
>;
export const listScratchAccountsCustomByOrgId = /* GraphQL */ `query ListScratchAccountsCustomByOrgId(
  $organizationID: ID!
  $nextToken: String
  $limit: Int
) {
  listScratchAccountsCustomByOrgId(
    organizationID: $organizationID
    nextToken: $nextToken
    limit: $limit
  ) {
    items {
      lyzaUsername
      orgID
      status
      createAccountFailedDateTimes
      confirmEmailFailedDateTimes
      scratchUsername
      scratchEmail
      scratchPassword
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListScratchAccountsCustomByOrgIdQueryVariables,
  APITypes.ListScratchAccountsCustomByOrgIdQuery
>;
export const listWhatsAppMessagesByCustomerPhoneNumber = /* GraphQL */ `query ListWhatsAppMessagesByCustomerPhoneNumber(
  $businessPhoneNumber: ID!
  $customerPhoneNumber: String!
  $nextToken: String
  $limit: Int
) {
  listWhatsAppMessagesByCustomerPhoneNumber(
    businessPhoneNumber: $businessPhoneNumber
    customerPhoneNumber: $customerPhoneNumber
    nextToken: $nextToken
    limit: $limit
  ) {
    items {
      messageID
      customerNumber
      businessPhoneNumber
      isFromBizToCustomer
      timestampInSecond
      status
      textMessage
      imageS3Key {
        s3Key
        versionNumber
        documentType
        __typename
      }
      videoS3Key {
        s3Key
        versionNumber
        documentType
        __typename
      }
      documentS3Key {
        s3Key
        versionNumber
        documentType
        __typename
      }
      reactionEmoji
      quotedMessageId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListWhatsAppMessagesByCustomerPhoneNumberQueryVariables,
  APITypes.ListWhatsAppMessagesByCustomerPhoneNumberQuery
>;
export const getOrgAwardById = /* GraphQL */ `query GetOrgAwardById($id: ID!) {
  getOrgAwardById(id: $id) {
    id
    organizationID
    name
    descriptionMarkdown
    authorizedBy
    awardIconPath
    imageS3Key {
      s3Key
      versionNumber
      documentType
      __typename
    }
    numberPeopleAwarded
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrgAwardByIdQueryVariables,
  APITypes.GetOrgAwardByIdQuery
>;
export const getUserAwardById = /* GraphQL */ `query GetUserAwardById($id: ID!) {
  getUserAwardById(id: $id) {
    id
    organizationID
    name
    descriptionMarkdown
    authorizedBy
    awardIconPath
    imageS3Key {
      s3Key
      versionNumber
      documentType
      __typename
    }
    numberPeopleAwarded
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserAwardByIdQueryVariables,
  APITypes.GetUserAwardByIdQuery
>;
export const listOrgAwards = /* GraphQL */ `query ListOrgAwards($organizationId: ID!, $nextToken: String) {
  listOrgAwards(organizationId: $organizationId, nextToken: $nextToken) {
    items {
      id
      organizationID
      name
      descriptionMarkdown
      authorizedBy
      awardIconPath
      imageS3Key {
        s3Key
        versionNumber
        documentType
        __typename
      }
      numberPeopleAwarded
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrgAwardsQueryVariables,
  APITypes.ListOrgAwardsQuery
>;
export const listUserOrgAwards = /* GraphQL */ `query ListUserOrgAwards(
  $username: ID!
  $organizationId: ID!
  $nextToken: String
) {
  listUserOrgAwards(
    username: $username
    organizationId: $organizationId
    nextToken: $nextToken
  ) {
    items {
      id
      organizationID
      username
      awardID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserOrgAwardsQueryVariables,
  APITypes.ListUserOrgAwardsQuery
>;
